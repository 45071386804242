
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url('https://fonts.googleapis.com/css?family=Catamaran:100,400|Lora|Pragati+Narrow|Raleway|Roboto+Condensed:300,400|Source+Sans+Pro:300,400');



    // font-family: 'Roboto Condensed', sans-serif;
    //
    // font-family: 'Lora', serif;
    //
    // font-family: 'Catamaran', sans-serif;
    //
    // font-family: 'Source Sans Pro', sans-serif;
    //
    // font-family: 'Raleway', sans-serif;


// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//card design css
@import "cards";

h1,h2,h3,h4,h5,h6 {
  color:#000000;
}

//sticky footer:
.flexContainer {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.flexContainer .mainContainer {
  flex: 1;
  width:100%;
}
.flexContainer footer img {
  width:150px;
  height:auto;
}
.flexContainer footer img.centered {
  width:250px;
  margin:0 auto;
  display:block;
}

.emphasizedSection {
  background-color:#195780;
  h1,h2,h3,h4,h5,h6 {
    color:#FFFFFF;
  }
  input {
    border:2px solid #FFFFFF;
    border-radius:8px;
    background-color:transparent;
    vertical-align: middle;
    color:#FFFFFF;
    text-align:center;
  }
  .bigInput {
    display:block;
    margin:35px auto;
    font-size:28px;
    padding:8px;
    width:67%;
  }
  .smallInput {
    width:100px;
    padding:4px;
    font-size:20px;
  }
}
.darkBlue {
  color:#195780;
}
.secondarySection {
  background-color: #33afb8;
  h1,h2,h3,h4,h5,h6 {
    color:#FFFFFF;
    text-align:center;
  }
  p {
    color:#FFFFFF;
    text-align:justify-left;
    font-size:20px;
    margin-bottom:18px;
  }
}

.headerSection {
  h1,h2,h3,h4,h5,h6 {
    text-align:center;
  }
  .logo {
    margin:0 auto;
    height:100px;
    width:auto;
    display:block;
  }
}
.forwardNavButton, .backNavButton {
  background-color:#195780;
  text-align:center;
  color:#FFFFFF;
  font-weight:bold;
  margin:10px auto;
  display:block;
  font-size:22px;
  width:200px;
  border-color:#333333;
}
.backNavButton {
  background-color:#FFFFFF;
  text-align:center;
  color:#555555;
  font-weight:bold;
  margin:10px auto;
  display:block;
  font-size:22px;
  width:200px;
  border-color:#555555;
  border-width:2px;
}
/*ugh this is stupid will make better later probably. create a dumb element
 to  allow individual vue "pages" to make the dumb background color
 correct after the container element ends if it
 doesn't take up the whole height. */
.pageStretcher {
  position:absolute;
  left:0px;
  bottom:0px;
  height:500px;
  display:block;
  width:100%;
  z-index:-10000;
}


//---------------- FLIP STUFF ---------------------

.flip-container.flip .flipper {
  transform: rotateY(180deg);
}
.flip-container {
  perspective: 1000px;
  //width:500px;
  height:295px;
}


//for all mobile devices, scale down flip container to correct width
@media screen and (min-width:320px) and (max-width:767px){
  .flip-container {
    width:100%;
  }
  .design-separation {
    margin-bottom:60px;
  }
  //and make the cardDesignContainer not absolutely positioned since we aint flippin:
  .cardDesignContainer, .front, .back {
    transform-origin: top center;
    position:relative;
    margin-left: 50%;
    //transform: translateX(-50%) scale(.77,.77);
  }

}
//scale the cards for very specific screen sizes
//tiny phones
@media screen and (max-width:359px) and (min-width:320px){
  .cardDesignContainer {
    transform:translateX(-50%)  scale(.58,.58);// translateX(-27%);
  }
  .flip-container {
    height:172px;
  }
}
//samsung galaxy 7 and similar
@media screen and (max-width:413px) and (min-width:360px){
  .cardDesignContainer {
    transform:translateX(-50%)  scale(.65,.65);
  }
  .flip-container {
    height:190px;
  }
}
//iphone 6plus and similar
@media screen and (max-width:559px) and (min-width:414px){
  .cardDesignContainer {
    transform:translateX(-50%)  scale(.77,.77);
  }
  .flip-container {
    height:230px;
  }
}
//landscape view
@media screen and (max-width:767px) and (min-width:560px){
  .cardDesignContainer {
    transform: none;
    margin-left:auto;
    margin-right:auto;
    position:relative;
  }
  .prologueFlipContainer .cardDesignContainer {
    margin-left:50%;
    margin-right:0;
  }
}

@media(max-width:960px) and (min-width:768px){
  .flip-container {
    width:350px;
    height:206px;
  }
  .selectionContainer .flip-container {
    width:300px;
    height:177px;
    margin-left:49px;
  }
  .selectionContainer .flip-container.leftAligned{
    margin-right:49px;
    margin-left:0;
  }
  .selectionContainer .cardHolder h6 {
    margin-left:49px;
    margin-right:0px;
  }
  .selectionContainer .cardHolder:nth-of-type(even) h6 {
    margin-right:49px;
    margin-left:0px;
  }
}
@media(min-width:961px){
  .flip-container {
    margin-left:auto;
    margin-right:auto;
  }
}
@media screen and (max-width:767px){
  .flip-container {

  }
  .flexContainer footer img, .flexContainer footer img.centered {
    margin: 10px auto;
    position: relative;
    display: block;
    width:150px;
  }
  #app {
    width: 100%;
    overflow: hidden;
  }
}
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}


/* front pane, placed above back */
