.cardFront2.cardDesignContainer {
  background-image:url('#{$base-path}card_assets/2front.png');

  .safeMargin {
    div {
      color:#232323;
      font-family: 'Pragati Narrow', 'times new roman', serif;
      font-size:1.4em;
      span {
        /*font-size:1.4em;*/
        color:#232323;
        font-family: 'Pragati Narrow', 'times new roman', serif;
      }
    }
    .name {
      top:0%;
      right:0%;
      left:initial;
      font-size:3em;
      font-family: 'Lora', 'times new roman', serif;
      text-transform: capitalize;
      width: 100%;
      text-align: right;
    }
    .title {
      top:23%;
      right:0%;
      left:initial;
      text-transform: uppercase;
      width: 100%;
      text-align: right;
    }
    .iconHolder {
      left: 52%;
      top: 52.8%;
      line-height: 1.47em;
      width: 9%;
      white-space: nowrap;
      text-align: center;
      span {
        font-size:1em!important;
        position:relative;
        img {
          height:1.5em;
          width:auto;
          top:0;
          left:0;
        }
        img.phoneImg {
          height: 1.1em;
        }
        img.websiteImg {
          height: 1.7em;
        }
        img.emailImg {
          margin-left:0em;
        }
      }
    }
    .rightBlock{
      left:60%;
      width:39.8%;
      top:53.3%;
      line-height:1.5em;
      span {

      }
    }
    .email {
      top:62.5%;
    }
    .phone {
      top:51.5%;
    }
    .website {
      top:75.5%;
    }
    div.company {
      top: 52.3%;
      width:48%;
      left:0%;
      line-height:1em;
      vertical-align: bottom;
      // font-size: 1.8em;
      font-weight:normal;
      font-family: 'Lora', 'times new roman', serif;
      span {
        font-size: 1.4em;
        // color:#919FA0!important;
      }
    }

    .addressBlock {
      top: 63%;
      left: 0%;
      width:48%;
      line-height: 1.1em;
    }
  }
}
.cardBack2.cardDesignContainer {
  background-image:url('#{$base-path}card_assets/2back.png');
  .safeMargin {
    .initials {
      bottom:0%;
      left:4%;
      font-size:8em;
      line-height:1.2em;
      color:#232323;
      font-family: 'Lora', 'times new roman', serif;
    }
  }
}
