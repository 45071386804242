.cardFront3.cardDesignContainer {
  background-image:url('#{$base-path}card_assets/3frontnew.png')!important;
  .safeMargin {
    div {
      color:#232323;
      font-family: 'Catamaran', sans-serif;
      font-weight:100;
      font-size:1.3em;
      span {
        color:#232323;
      }
      left:20%;
      width:80%;
      img {
        height:1.4em;
        width:auto;
      }
    }
    div.name {
      top: 0%;
      color: #195780;
      span {
        color: #195780!important;
      }
      font-size: 2.1em;
      text-transform: uppercase;
      font-weight: normal;
    }

    .iconHolder {
      top:25%;
      line-height: 1em;
      width: 9%;
      white-space: nowrap;
      text-align: center;
      left:18%;
      span {
        font-size:1em!important;
        position:relative;
        img {
          height:1.5em;
          width:auto;
          top:0;
          left:0;
        }
        img.phoneImg {
          height: 1.1em;
        }
        img.websiteImg {
          height: 1.7em;
          margin-top:-.2em;
        }
        img.emailImg {
          margin-left:0em;
        }
      }
    }
    .infoBlock{
      top:25%;
      left:25%;
      width:70%;
      line-height:1.3em;
      span {

      }
    }

    .phone {
      top:25%;
      img {
        height: .9em;
        margin-right: .3em;
        margin-left: .2em;
        margin-top: -.2em;
      }
    }
    .email {
      top:34.5%;
      img {
        margin-top: -.3em;
      }
    }
    .website {
      top:44%;
    }
    .company {
      top: 62%;
      font-size: 1.5em;
      font-weight: normal;
      text-transform: uppercase;
    }
    .addressBlock {
      top: 75%;
      line-height: 1em;
    }
  }
}
.cardBack3.cardDesignContainer {
  background-image:url('#{$base-path}card_assets/3back.png');
  background-position: center;
  position:relative;
  div {
    position:absolute;
    width: 46%;
    margin-left: 27%;
    left: 0%;
    text-align: center;
    color: #FFFFFF!important;
    font-weight:normal;
    span {
      color: #FFFFFF!important;
    }
  }
  div.name {
    top: 40%;
    font-size: 1.7em;
    line-height:1em;
    color: #FFFFFF!important;
    height:8%;
    span {
      line-height:1em;
      vertical-align: bottom;
    }
    //hack to make vertical align and line-height 100% by shoving a height widening psuedo element in there
    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      // margin-right: -0.25em;
    }
  }
  .title {
    top: 53.5%;
    font-size: 1.4em;
    text-transform:lowercase;
    color: #FFFFFF!important;
    line-height:1em;
    height:8%;
    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      // margin-right: -0.25em;
    }
    span {
      line-height:1em;
      vertical-align: top;
    }
  }
  @media print {
    div.name span {
      font-weight:bold!important;
    }
    .title span {
      font-weight:bold!important;
    }
  }
}
