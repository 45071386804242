.cardFront1.cardDesignContainer {

  background-image:url('#{$base-path}card_assets/1front.png')!important;
  .safeMargin {
    div {
      color:#232323;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight:lighter;
      font-size:1.4em;
      left: 0%;
      width:100%;
      img {
        margin-top:-.1em;
        height:1.5em;
        width:auto;
      }
    }
    .name {
      top: 0;
      font-size: 2.85em;
      line-height:1em;
      span {
        font-weight:normal;
        text-transform: uppercase;
      }
    }
    .title {
      top: 16%;
      text-transform: uppercase;
    }

    .phone {
      top:34%;
      img {
        height: 1.0em;
        margin-right: .2em;
        margin-left: .2em;
        margin-top: -.2em;
      }
    }
    .email {
      top:42.5%;
    }
    .website {
      top:51.5%;
    }
    .company {
      top:69%;
      font-weight:normal;
      text-transform: uppercase;
    }

    .addressBlock {
      top: 80%;
      line-height: 1.07em;
      font-size: .95em;
    }
  }
}

.cardBack1.cardDesignContainer {
  background-image:url('#{$base-path}card_assets/1back.png');
}
