.cardFront4.cardDesignContainer {
  background-image:transparent; /*url('/card_assets/4frontgreensmall.png');*/
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-position: 17% 50%;

  .backgroundImg {
    width: auto;
    height: 80%;
    display: block;
    position: absolute;
    top: 10.5%;
    left: 12.3%;
    z-index: 0;
    @media print {
      left: 13.8%;
    }
  }

  .safeMargin {
    div {
      color:#232323;
      font-family: 'Source Sans Pro', sans-serif;
      font-size:1.1em;
      font-weight:300;
    }
    .name {
      top: 25.5%;
      /*right: 3%;*/
      left: 0%;
      width: 35%;
      font-size: 2.3em;
      text-transform: uppercase;
      line-height: 1.1em;
      text-align: center;
    }
    .title {
      top:59%;
      text-align: center;
      text-transform: lowercase;
      left: 0%;
      width: 35%;
    }
    .rightBlock {
      position:absolute;
      width: 55%;
      right: 0%;
      // background-color: rgba(35,254,98,.4);
      text-align: center;
      top:13.5%;
      div {
        position:relative;
        text-align: center;
        text-transform: uppercase;
        line-height:1.2em;
        top:auto;
        left:auto;
        right:auto;
        bottom:auto;
      }
      div.website, div.email {
        text-transform: lowercase;
      }
    }
    .email {

    }
    .phone {
      margin:8% 0;
    }
    .website {

    }
    div.company {
      font-weight:400!important;
      span {
        font-weight:400!important;
      }
      font-size:1.3em;
    }
  }
}
.cardBack4.cardDesignContainer {
  // background-image:url('/card_assets/4back.png');
}
