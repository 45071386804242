.cardDesignContainer {

  /*important: sets EM stuff:*/
  font-size:13px;
  text-align: left;
  width:500px;
  height:295px;
  border:1px solid black;
  position:relative;
  background-color: #FFFFFF;
  // background-image:url('http://i.imgur.com/bwpAmPA.png');
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  padding:5%;

  @media print {
    padding:7.3%;
  }

  .safeMargin {
    height:100%;
    width:100%;
    position:relative;
    font-size:1em;
    div {
      position:absolute;
      color:#FFFFFF;
    }
  }

  .name {
    top:15%;
    left:3%;
    font-size:190%;
    text-transform: uppercase;
  }
  .title {
    top:28%;
    left:3%;
    font-size:100%;
    text-transform: uppercase;
  }
  .email {
    top:60%;
    left:3%;
    font-size:100%;
  }
  .phone {
    top:68%;
    left:3%;
    font-size:100%;
  }
  .website {
    top:76%;
    left:3%;
    font-size:100%;
  }
  .company {
    top:84%;
    left:3%;
    font-size:100%;
  }
  .addressBlock {
    top:15%;
    right:3%;
    font-size:100%;
    text-align:left;
  }
}

@media(max-width:960px) and (min-width:768px){
  .cardDesignContainer {
    font-size:10px;
    width:350px;
    height:206px;
  }
  .selectionContainer .cardDesignContainer {
    font-size:8.5px;
    width:300px;
    height:177px;
  }
  .cardDesignContainer.big, .big .cardDesignContainer {
    width:500px;
    height:295px;
      /*important: sets EM stuff:*/
      font-size:13px;
  }
}
@media(max-width:767px){
  .cardDesignContainer {
    position:relative;
  }
}

@import "cards/1";
@import "cards/2";
@import "cards/3";
@import "cards/4";
